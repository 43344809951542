import React from 'react';

import Layout from '../components/common/layout';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header';


const Terms = () => (
  <Layout>
    <Header />

    <div id="wrapper">
      <section id="main" className="wrapper">
        <div className="inner">
        	<h1 className="major">Terms & Conditions</h1>
        	<h2>General Terms & Conditions</h2>
        	<h3>1. TERMS</h3>
        	<p>By accessing this web site, you are agreeing to be bound by these web site Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this web site are protected by applicable copyright and trade mark law</p>
			<h3>2. CONTRACT CONCLUSION</h3>
			<p>With the verbal or written registration (i.e. the booking from the customers) in which Sintra Climbing Tours or one of their businesses can execute, a binding contract exists between the customer and the organizer. Through the booking, the customer acknowledges the general business conditions as part of the contract between himself and the organizer.</p>
			<h3>3. OBJECTS OF AGREEMENT</h3>
			<p>The organizer assumes responsibility for providing the requested service as described in the advertisements and/or in the confirmation order. Service extensions can be considered upon consultation with the organizer. Any extra costs will be borne by the customer.</p>
			<h3>4. PRICES</h3>
			<p>The effective prices for the offered activities can be drawn from the current advertisements from the organizer. The prices in the brochure are quoted per person in Euros and include the statutory value tax. Prices are subject to change.</p>
			<h3>5. TERMS OF PAYMENT</h3>
			<p>The booked activities can be paid as follows: a. The total amount is to be paid at the conclusion of the activity for bookings made within 30 days prior to commencement. b. Advance payment of 30% of the total price of the booked activity for bookings which are made more than 30 days prior to commencement. The balance is to be paid on the day of the activity. c. For group reservations the advance payment of 30% of the total price of the booked activity. The balance is to be paid on the day of the activity. In the event of untimely payment, the organizer reserves the right to refuse the activity or withdraw from the contract. The resulting cancellation costs will be charged to the customer in accordance to number 6. This is based on the number of registered participants at the time of withdrawal from the contract or refusal of services.</p>
			<h3>6. CANCELLATION OR CONTRACT MODIFICATION BY THE USER </h3>
			<p>Cancellations of contracts must be in writing. Cancellations are only valid after consulting with the organizer and obtaining their agreement. All received documents (confirmation, tickets, coupons, etc) must be attached and returned at this time.</p>
			<p>In the event of a complete cancellation, the following percent of the arrangement costs will be charged to the contracting party:<br/>
			a. For group reservations (more than 8 participants): <br/>
			- 7 to 15 days before the activity: 30% 2 to 7 days before the activity: 75%<br/>
			- 1 day before the activity or non-appearance: 100%<br/>
			b. For individuals (up to 8 participants): <br/>
			- 1 day before the activity: 100%</p>
			<p>In the case of the third party suppliers, the cancellation conditions of the respective supplier apply. These potential costs can be additionally added to the bill. In the event of a partial cancellation of a group reservation (reduction of the number of participants) the following percent of the arrangement costs will be charged per person to the contracting party: <br/>
			- 2-7 days before the activity: 30% <br/>
			- 1 day before the activity: 75%</p>
			<p>The customer is not entitled to a refund in the event of a later start or an early departure from the activity. Extra costs incurred due to a later start, an early departure, or the postponement of an activity, are to be paid by the customer.</p>
			<h3>7. CANCELLATION OR CHANGE OF CONTRACT BY THE ORGANIZER</h3>
			<p>A minimum number of participants are required for certain programs. The organizer reserves the right to withdraw from the contract also on short notice. If the fulfillment of a contract is not possible at another time or if the customer cannot take part in the alternative services offered, any paid fees will be refunded minus charges for services rendered. Further damage claims are not possible. The organizer reserves the right to cancel the program on short notice if participants cause the fulfillment of a contract to be endangered or made impossible by their behavior, their omissions or other actions. In this case the regulation of the cancellation costs applies in accordance with number 6. If a program or parts of a program cannot be carried out due to Acts of God, safety concerns by the organizer, official regulations, strikes or uncertain weather and nature conditions, the organizer reserves the right to cancel or cut short the activities on short notice. Paid fees will be returned minus charges for services rendered, any expenditures and the handling fee. Please note that a danger-free completion of the activity is in everyone’s interest. All decisions made by the activity leaders are final. The organizer expressly reserves the right to modify the program. The organizer will strive for an equivalent service replacement.</p>
			<h3>8. CONDITIONS OF PARTICIPATION, OBLIGATIONS OF THE PARTICIPANT</h3>
			<p>Good health is necessary in order to take part in all activities. The participants are obligated to inform the organizer of any possible health problems. Under no circumstances may participants take part in the activities while under the influence of alcohol, drugs, psychiatric drugs or any similar substances. The participants are obliged to fulfill the conditions of participation and agree to strictly follow the instructions of the organizer, the guides, activity leader, and assistants. If the Conditions of Participation are not fulfilled or if instructions are disregarded, the organizer reserves the right to prohibit participation.</p>
			<h3>9. INSURANCE</h3>
			<p>The participants are not insured by the organizer. The participants are obliged to insure that they have sufficient health and accident insurance coverage (including high risk sport coverage).</p>
			<h3>10. APPEALS</h3>
			<p> Should the activity lead to appeals or damages, these are to be communicated immediately, in writing, to the activity leader (i.e. activity provider), and these are to be confirmed in writing by the responsible leader. The activity leader, however, shall not have the right to acknowledge claims, for which reason a confirmation shall not have the effect of an acknowledgement of wrongdoing. The activity leader (i.e. activity provider) will make every effort, within the confines of the program and the possibilities available, to remedy the situation. Should the situation be insufficiently remedied or not be remedied, or the participant wishes to make damage claims, these must be received by the organizer within four weeks of the contractual end of the activity at the booking agency. The participant’s appeal is to be accompanied by the confirmation of the activity provider/activity leader as well as any other relevant evidence. In the case of delayed complaints or complaints with omissions during the activity or delayed submissions of the claims at the booking agency ,all appeals will be deemed invalid.</p>
			<h3>11. LIABILITY</h3>
			<p>Damage claims against the organizer or the assistants are excluded, as far as the damage was not caused by negligence or intentionally. The organizer is authorized to have assistants or call on third parties to provide valued services. Should the organizer lawfully transfer the execution of the activity to a third party, the organizer shall not be held responsible for the third party’s actions or neglect during execution of the activity. The organizer will especially not be held responsible for damages which are caused by the actions or neglect of the activity leader should this conduct not fall under his/her contracted responsibility; as cause of actions of third parties, other participants, the participant (especially under point 2), acts of God, natural occurrences, official regulations etc or damages caused by delayed return to the starting point of the activity. Should the participant not follow the instructions of the organizer, activity leader, etc, all liability of the organizer is dropped.</p>
			<h3>12. IMAGERY</h3>
			<p>Sintra Climbing Tours reserves the right to use images or video of any participants for any lawful purpose, including for example such purposes as publicity, illustration, advertising, and Web content.</p>

        </div>
      </section>
    </div>

    <Footer />
  </Layout>
);

export default Terms;
